import { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import { ApiSerive } from "./apiService";

function UserList({ setCurrentUser, currentUser }) {
  const service = new ApiSerive();
  const step = 10;
  const defaltData = {
    count: 0,
    limit: 10,
    offset: 0,
    results: [],
  };
  const [userData, setUserData] = useState(defaltData);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState([]);

  useEffect(function () {
    updateUserData(userData.limit, userData.offset);
  }, []);
  useEffect(function () {
    console.log('currentUser updated');
    console.log(currentUser);
  }, [currentUser]);

  const updateUserData = async (limit, offset) => {
    const resultData = await service.getUsers(limit, offset);
    resultData["limit"] = limit;
    resultData["offset"] = offset;
    // let users = [...resultData["results"]]
    // resultData.count = 4
    // resultData["results"] = [users[0], users[0], users[0], users[0]];
    setUserData(resultData);
    setTotalPages(Math.ceil(resultData.count / step));
  };
  const updatePage = (pageIndex) => {
    let offs = pageIndex * step;
    let lim = offs + step;
    setPage(pageIndex);
    updateUserData(lim, offs);
  };
  return (
    <Table hover>
      <thead>
        <tr>
          <th>#</th>
          <th>Tg</th>
          <th>Email</th>
          <th>Остаток</th>
        </tr>
      </thead>
      <tbody>
        {userData.results.map((user, index) => {
          return (
            <tr
              key={index}
              className=""
              onClick={() => {
                setCurrentUser(user);
              }}
            >
              <td>{user.id}</td>
              <td>
                {user.username} (#{user.telegram_id})
              </td>
              <td>{user.email}</td>
              <td>{user.count_subscription} шт.</td>
            </tr>
          );
        })}
      </tbody>
      {totalPages > 1 && (
        <tfoot>
          <tr>
            <td colSpan="1">Итого: {userData.count}</td>
            <td colSpan="1">
              стр. {page + 1} из {totalPages}
            </td>
            <td colSpan="2">
              <Pagination className="pt-3">
                {!!page && (
                  <Pagination.First
                    onClick={() => {
                      updatePage(0);
                    }}
                  />
                )}
                {!!page && (
                  <Pagination.Prev
                    onClick={() => {
                      updatePage(page - 1);
                    }}
                  />
                )}
                {totalPages > page && (
                  <Pagination.Next
                    onClick={() => {
                      updatePage(page + 1);
                    }}
                  />
                )}
                {totalPages > page && (
                  <Pagination.Last
                    onClick={() => {
                      updatePage(totalPages);
                    }}
                  />
                )}
              </Pagination>
            </td>
          </tr>
        </tfoot>
      )}
    </Table>
  );
}
export default UserList;
