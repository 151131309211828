import { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { ApiSerive } from "./apiService";
import Pagination from "react-bootstrap/Pagination";

function SubList({ currentUser }) {
  const service = new ApiSerive();
  const step = 10;
  const defaltData = {
    count: 0,
    limit: 10,
    offset: 0,
    results: [],
  };
  const [subData, setSubData] = useState(defaltData);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState([]);
  useEffect(function () {
    updatesubData(subData.limit, subData.offset);
  }, [currentUser]);

  const updatesubData = async (limit, offset) => {
    const resultData = await service.getSubs(limit, offset, currentUser?.id);
    resultData["limit"] = limit;
    resultData["offset"] = offset;
    setSubData(resultData);
    setTotalPages(Math.ceil(resultData.count / step));
  };
  const updatePage = (pageIndex) => {
    let offs = pageIndex * step;
    let lim = offs + step;
    setPage(pageIndex);
    updatesubData(lim, offs);
  };
  return (
    <Table hover responsive="md">
      <thead>
        <tr>
          <th>#</th>
          <th>Название</th>
          <th>Tg</th>
          <th>url</th>
          <th>До</th>
          <th>Цена</th>
        </tr>
      </thead>
      <tbody>
        {subData.results.map((sub, index) => {
          return (
            <tr key={index} className="">
              <td>{sub.id}</td>
              <td>{sub.title}</td>
              <td>
                {sub.user.username} (#{sub.user.telegram_id})
              </td>
              <td>{sub.url}</td>
              <td>{new Date(sub.actived_to * 1000).toLocaleString("ru")}</td>
              <td>
                {sub.current_price} ({sub.old_price || ""}) руб.
              </td>
            </tr>
          );
        })}
      </tbody>
      {totalPages > 1 && (
        <tfoot>
          <tr>
            <td colSpan="1">Итого: {subData.count}</td>
            <td colSpan="1">
              стр. {page + 1} из {totalPages}
            </td>
            <td colSpan="4">
              <Pagination className="pt-3">
                {!!page && (
                  <Pagination.First
                    onClick={() => {
                      updatePage(0);
                    }}
                  />
                )}
                {!!page && (
                  <Pagination.Prev
                    onClick={() => {
                      updatePage(page - 1);
                    }}
                  />
                )}
                {totalPages > page && (
                  <Pagination.Next
                    onClick={() => {
                      updatePage(page + 1);
                    }}
                  />
                )}
                {totalPages > page && (
                  <Pagination.Last
                    onClick={() => {
                      updatePage(totalPages);
                    }}
                  />
                )}
              </Pagination>
            </td>
          </tr>
        </tfoot>
      )}
    </Table>
  );
}
export default SubList;
