import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Cookies from "js-cookie";

import { ApiSerive } from "./apiService";
import LoginForm from "./LoginForm";
import UserList from "./UserList";
import SubList from "./SubList";
import UpdateUserForm from "./UpdateUserForm";

import "./App.css";

function App() {
  const [isAuth, setIsAuth] = useState(!!Cookies.get("token"));
  const [currentUser, setCurrentUser] = useState();

  const updateAuth = (token) => {
    Cookies.set("token", token);
    setIsAuth(!!token);
  };

  return (
    <div className="App">
      {isAuth && (
        <Container>
          <Row className="mt-5">
            <Col md="6">
              <Card>
                <Card.Header>Юзеры</Card.Header>
                <Card.Body>
                  <UserList setCurrentUser={setCurrentUser} currentUser={currentUser} />
                </Card.Body>
              </Card>
            </Col>
            <Col md="6">
              {currentUser && (
                <Card>
                  <Card.Header>#{currentUser.username}</Card.Header>
                  <Card.Body>
                    <UpdateUserForm currentUser={currentUser} setCurrentUser={setCurrentUser} />
                  </Card.Body>
                </Card>
              )}
            </Col>
          </Row>
          <Row className="mt-5">
            <Col md="auto">
              <Card>
                <Card.Header>Подписки</Card.Header>
                <Card.Body>
                  <SubList currentUser={currentUser} />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
      {!isAuth && <LoginForm setIsAuth={updateAuth} />}
    </div>
  );
}

export default App;
