import Cookies from 'js-cookie';

export class ApiSerive {

  constructor() {
    // this.baseUrl = process.env.REACT_APP_API_URL || "http://0.0.0.0:5800/web/api";
    this.baseUrl = "https://manage.pricer-bot.ru/web/api";
    this.token = Cookies.get('token')
  }

  getFetchOptions(method) {
    const headers = {
      "Content-type": "application/json",
    }
    if (this.token){
      headers['Authorization'] = `Bearer ${this.token}`
    }
    return {
      method,
      headers,
    }
  }

  async makeRequest(url, options) {
    const response = await fetch(
      url, options
    )
    if (response.ok) {
      let json = await response.json();
      return json
    } else {
      alert("Ошибка HTTP: " + response.status);
    }
  }

  async getUsers(limit, skip) {
    const url = `${this.baseUrl}/users/?` + new URLSearchParams({ limit, skip }).toString()
    const options = this.getFetchOptions('GET');
    const respData = await this.makeRequest(url, options);
    return respData
  }
  async getSubs(limit, skip, user_id) {
    const query = { limit, skip }
    if(user_id) {
      query.user_id = user_id;
    }
    const url = `${this.baseUrl}/subscriptions/?` + new URLSearchParams(query).toString()
    const options = this.getFetchOptions('GET');
    const respData = await this.makeRequest(url, options);
    return respData
  }

  async doLogin(email, password) {
    const url = `${this.baseUrl}/login/`
    const options = this.getFetchOptions('POST')
    options['body'] = JSON.stringify({ email, password })
    const respData = await this.makeRequest(url, options) 
    return respData
  }
  async updateUser(userId, email, count_subscription) {
    const url = `${this.baseUrl}/users/${userId}/`
    const options = this.getFetchOptions('PATCH')
    options['body'] = JSON.stringify({ email, count_subscription })
    const respData = await this.makeRequest(url, options) 
    return respData
  }


}

