import { useState, useEffect } from "react";

import Alert from "react-bootstrap/Alert";
import { Form, Button, Container, Row, Col } from "react-bootstrap";

import { ApiSerive } from "./apiService";

function UpdateUserForm({ currentUser, setCurrentUser }) {
  const [countSub, setCountSub] = useState(0);
  const [email, setEmail] = useState("");
  const [isSaved, setIsSaved] = useState(false);
  const service = new ApiSerive();
  const handleSubmit = async (event) => {
    event.preventDefault();
    const respUser = await new ApiSerive().updateUser(
      currentUser.id,
      email,
      countSub,
    );
    if (respUser) {
      setCurrentUser(respUser);
      setIsSaved(true);
      setTimeout(() => {
        setIsSaved(false);
      }, 5000);
    }
  };
  useEffect(() => {
      setCountSub(currentUser.count_subscription)
      setEmail(currentUser.email)
  }, [currentUser])

  return (
    <Container>
      <Row className="justify-content-md-center mt-5">
        <Col xs={12} md={6}>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="email пользователя"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Остаток подписок</Form.Label>
              <Form.Control
                type="number"
                placeholder="Целое число"
                value={countSub}
                step="1"
                min="0"
                max="100"
                inputMode="numeric"
                pattern="\d*"
                onChange={(e) => setCountSub(e.target.value)}
              />
            </Form.Group>
            {isSaved && <Alert variant="success">Сохранили!</Alert>}
            <Button variant="primary" type="submit" className="w-100">
              Сохранить
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default UpdateUserForm;
